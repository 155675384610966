.App {
  text-align: center;
}

.App-logo {
  height: 15vmin;
  pointer-events: none;
  margin: 5vmin;
  /* border-width: 1px;
    border-style: solid;
    border-color: black; */
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.row {
  display: flex;
  flex-direction: row;
}

.centered {
  display: flex;
  justify-content: center;
  align-items: center
}

.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.spaced {
  display: flex;
  justify-content: space-evenly;
}

.grown {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  border-width: 1px;
    border-style: solid;
    border-color: black;
}

.bottom {
  display: flex;
  padding-top: 12vmin;
  /* align-items: flex-end;
  border-width: 1px;
  border-style: solid;
  border-color: black; */
}

body {
  font-family: 'OpenSans', sans-serif;
}

@font-face {
  font-family: 'OpenSans';
  src: url('./assets//OpenSans-VariableFont_wdth\,wght.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.social-button {
  gap: 5px;
  align-items: center;
  margin: 5px;
  border-color: black;
  border-width: 1px;
  border-style: dotted;
  border-radius: 50px;
  padding: 5px 10px;
  max-height: 65px;
  min-width: 200px;
  width: 250px;
  max-width: 500px;
  text-decoration: none;
  color: inherit;
  transition: background-color 0.3s;
}

.social-button:hover {
  background-color: rgb(117, 117, 117);
}

@media (prefers-color-scheme: dark) {
  .social-button {
    background-color: #afafaf;
  }
}